<template>
  <div class="possible">
    <input type="button" @click="getPossibleGuests()" value="Get Guests" />
    <div v-if="answer">
      <table>
        <tr>
          <td colspan="3">Guest attendees</td>
        </tr>
        <tr>
          <td>ID</td>
          <td>email</td>
          <td>isAllowed</td>
          <td>isGuest</td>
        </tr>
        <tr v-for="({ email }, idx) of answer" :key="idx">
          <td>
            {{ idx + 1 }}
          </td>
          <td class="textleft">
            {{ email }}
          </td>
          <td>
            <input
              type="checkbox"
              id="checkbox"
              v-model="answer[idx].isAllowed"
              alt="Einladung akzeptiert?"
            />
          </td>
          <td>
            <input
              type="checkbox"
              id="checkbox"
              v-model="answer[idx].isGuest"
              alt="Einladung akzeptiert?"
              disabled
            />
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <input
              type="button"
              @click="sendAck(answer)"
              value="Antwort senden"
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import authHeader from "../services/auth-header";

import axios from "axios";

export default defineComponent({
  name: "GetPossibleGuest",
  data() {
    return {
      answer: "",
    };
  },
  methods: {
    getPossibleGuests() {
      const API_URL = "https://wedding.keuenhoff.com/backend/";
      //const API_URL = "http://localhost:8080/backend/";

      const { access_token, ...sendUser } = this.$store.state.auth.user;
      return axios
        .post(`${API_URL}possible`, sendUser, {
          headers: authHeader(),
        })
        .then((response) => {
          this.answerSuccess = true;
          this.answer = response.data;
          return response.data;
        })
        .catch((e) => {
          this.answerError = true;
          this.answer = e.response.data.message;
        });
    },
    sendAck() {
      const API_URL = "https://wedding.keuenhoff.com/backend/";
      //const API_URL = "http://localhost:8080/backend/";

      // console.log("sendACK: ", this.answer);
      return axios
        .post(`${API_URL}user/ack`, this.answer, { headers: authHeader() })
        .then((response) => {
          if (response) {
            // console.log(response);
          }
          this.answerSuccess = true;
          this.answer = response.data;
          return response.data;
        })
        .catch((e) => {
          this.answerError = true;
          this.answer = e.response.data.message;
        });
    },
  },
});
</script>

<style scoped>
.textleft {
  text-align: left;
}
.possible {
  margin: auto auto;
  border: 1px solid grey;
  margin: 0 0 8px 0;
  padding: 8px 0 8px 0;
}
</style>