import axios from 'axios';
import authHeader from './auth-header';

//const API_URL = 'http://localhost:8080/backend/'; 
const API_URL = 'https://wedding.keuenhoff.com/backend/';

class AuthService {


    login(user: { email: string; password: string; }) {
        return axios
            .post(API_URL + 'auth/login', {
                ...user
            })
            .then(response => {
                if (response.data.access_token) {
                    localStorage.setItem('token', JSON.stringify(response.data.access_token));
                }
                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('token');
    }

    isuser() {
        return axios.get(API_URL + 'profile', {
            headers: authHeader(),
        })
            .then(response => {
                return response.data;
            })
            .catch((e) => console.error(e));
    }

    register(user: { username: string; email: string; password: string; }) {
        return axios.post(API_URL + 'signup', {
            username: user.username,
            email: user.email,
            password: user.password
        });
    }
}

export default new AuthService();
