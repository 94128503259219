<template>
  <div class="login">
    <h1>Login</h1>
    <p v-if="$route.query.redirect">You need to login first.</p>
    <form class="loginForm" @submit.prevent="handleLogin">
      <table class="loginFormTable">
        <tr>
          <td class="label">
            <label for="email">Email:</label>
          </td>
          <td>
            <input
              autocomplete="email"
              v-model="email"
              placeholder="email"
              type="text"
            />
          </td>
        </tr>
        <tr>
          <td class="label">
            <label for="password">Password:</label>
          </td>
          <td>
            <input
              autocomplete="family-name"
              v-model="password"
              placeholder="password"
              type="password"
            />
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <button :disabled="loading">
              <span v-show="loading"></span>
              <span>Login</span>
            </button>
          </td>
        </tr>
      </table>
      {{ message }}
    </form>
  </div>
</template>

<script lang="js">
export default {
  name: "LoginItem",
  data() {
    return {
      username: "",
      email: "",
      password: "",
      error: false,
      loading: false,
      message: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/wedding");
    }
  },
  methods: {
    handleLogin() {
      const user = { email: this.email, password: this.password };
      this.loading = true;

      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/private");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.loginFormTable {
  margin: auto;
  padding: 3px 3px;
}
.label {
  display: flex;
  justify-content: left;
}
.error {
  color: red;
}
.login {
  padding: 20px;
  border: 1px solid grey;
  margin: 0 0 8px 0;
}

.loginForm {
  justify-content: center;
}
</style>
