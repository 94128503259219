<template>
  <div id="nav">
    <!-- |
    <router-link to="/">Home</router-link>
    | -->
    <!-- <router-link v-if="!currentUser" to="/login/">Login</router-link>
    {{ !currentUser ? " |" : null }}
    <router-link v-if="currentUser" to="/private">Wedding</router-link>
    {{ currentUser ? " | " : null }}
    <router-link v-if="currentUser" to="/escort">Neue Begleitung</router-link>
    {{ currentUser ? " |" : null }}
    <router-link v-if="currentUser" to="/answer">Antwort</router-link>
    {{ currentUser ? " |" : null }}
    <router-link v-if="showAdminBoard" to="/profile">Guestlist</router-link>
    {{ showAdminBoard ? " |" : null }}
    <router-link v-if="!currentUser || showAdminBoard" to="/register"
      >Registrieren</router-link
    >
    {{ showAdminBoard || !currentUser ? " |" : null }}
    <router-link v-if="showAdminBoard" to="/possible"
      >Registrierungen</router-link
    >
    {{ currentUser ? " |" : null }}
    <router-link v-if="currentUser" to="/logout">Logout</router-link>
    {{ currentUser ? " |" : null }} -->
    <router-view />
  </div>
</template>

<script lang="js">
import axios from 'axios';

export default {
  created() {
    const html = document.documentElement // returns the html tag
    html.setAttribute('lang', 'de')
  },
  // computed: {
  //   currentUser() {
  //     return this.$store.state.auth.user;
  //   },
  //   showAdminBoard() {
  //     if (this.currentUser && this.currentUser["roles"]) {
  //       return this.currentUser["roles"].includes("admin");
  //     }
  //     return false;
  //   },
  // },
  // methods: {
  //   logOut() {
  //     this.$store.dispatch("auth/logout");
  //     this.$router.push("/");
  //   },
  //   whichDevice() {
  //       console.table('User Device: ',  navigator.userAgent );
  //   }
  // },
};
</script>

<style lang="scss">
@font-face {
  font-family: "didot";
  src: url("@/assets/fonts/didot.woff") format("font-woff");
}
#app {
  font-family: didot;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-weight: bold;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

#nav {
  padding: 20px;
  border: 0px solid grey;
  margin: 0 0 8px 0;
  a {
    color: #2c3e50;
    font-size: 14pt;
    text-decoration: none;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
