/* eslint-disable */
import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordNormalized } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../components/Login.vue";
import GetPossibleGuest from "../components/getPossibleGuest.vue"
import store from "@/store";



const routes: Array<any> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/answer",
    name: "Answer",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "answer" */ "../views/Answer.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
  },
  {
    path: "/private",
    name: "Private",
    component: () =>
      import(/* webpackChunkName: "private" */ "../views/Private.vue"),
  },
  {
    path: "/profile",
    name: "UProfile",
    component: () =>
      import(/* webpackChunkName: "uprofile" */ "../views/Profile.vue"),
  },
  {
    path: "/possible",
    name: "PossibleGuests",
    component: GetPossibleGuest,
  },
  {
    path: "/escort",
    name: "escort",
    component: () =>
      import(/* webpackChunkName: "escort" */ "../views/Escort.vue"),
  },
  {
    // /search/screens -> /search?q=screens
    path: "/logout",
    name: "Logout",
    component: {
      async beforeRouteEnter(to: RouteLocationNormalized, from: RouteRecordNormalized, next: NavigationGuardNext) {
        await store.dispatch("auth/logout");

        router.push('/')
      }
    }

  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {

  const publicPages = ['/login', '/register', '/home', '/'];

  const authRequired: boolean = !publicPages.includes(to.path);
  const token = localStorage.getItem('token');
  // trying to access a restricted page + not logged in
  if (!authRequired) {
    next();
  } else if (authRequired && !token) {
    next('/login');
  } else {
    await store.dispatch("auth/check");
    next();
  }
});

export default router;
