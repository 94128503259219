<template>
  <div class="home">
    <!-- <img alt="Laura & Christian" src="../assets/wedding.jpg" /> -->
    just married . . .
    <a href="https://mail.keuenhoff.com:40443/fsdownload/eQ9QMbDLo/wedding
" target="_new">Hier geht es zu den Fotos</a>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeItem",
  components: {

  },
});
</script>

<style scoepd lang="scss">
.home {
  padding: 8px 8px;
  border: 0px solid grey;
  height: 100%;
  width: 100%;
  color: white;
}
a {
  color: white;
}
a:hover {
  color: #C0C0C0;
}

html {
  background: url(../assets/wedding.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-size: 34pt;
}
</style>
