import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

const app = createApp(App)

// app.config.errorHandler = (err, vm, info) => {
//     // handle error
//     // `info` is a Vue-specific error info, e.g. which lifecycle hook
//     // the error was found in
//     err ? console.error(err) : console.log('VM: ', vm, '\ninfo: ', info)
// }

app
    .use(store)
    .use(router)
    .mount("#app")