import { createStore } from "vuex";
import { auth } from "./auth.module";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {},
  plugins: [createPersistedState()],
  mutations: {},
  actions: {},
  strict: true,
  modules: {
    auth,
  },
});
